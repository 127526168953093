import React, { useEffect } from "react";
import defaultImage from "assets/imgs/default/feaures_default.jpg";
import "./styles.scss";
import { Box, Container, Stack, Typography } from "@mui/material";
import { theme } from "utils/theme";
import InstantAccess from "../InstantAccess";
import { getLandingFeatures } from "store/slices/landingSite/reducer";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { useSelector } from "react-redux";
import LoadingScreen from "components/LandingComponents/loading-screen";

export function Features() {
  const [fetchData] = useDispatchWithAbort(getLandingFeatures);

  const {
    features: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({ page: 1, page_size: 20 });
  }, [fetchData]);

  // const [features, setFeatures] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;
  // const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page

  // const [loading, setLoading] = useState(true);

  // const loadFeatures = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const data = await getApi("/getFeatresList", "POST", {
  //       page: currentPage,
  //       page_size: itemsPerPage,
  //     });
  //     if (data.records !== undefined && data.records.length > 0) {
  //       setFeatures((prevPosts) => [...prevPosts, ...data.records]);
  //       setIsLast(data.last); // If `data.last` is true, no more posts to load
  //     }
  //   } catch (error) {
  //     console.error("Error fetching features:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage]);

  // useEffect(() => {
  //   setFeatures([]);
  //   setCurrentPage(1);
  // }, []);

  // useEffect(() => {
  //   loadFeatures();
  // }, [currentPage, loadFeatures]);

  // const handleScroll = throttle(() => {
  //   if (isLast) return; // If it's the last page, no need to load more

  //   const scrollTop = window.scrollY;
  //   const windowHeight = window.innerHeight;
  //   const documentHeight = document.documentElement.scrollHeight;

  //   const scrollPercentage =
  //     (scrollTop / (documentHeight - windowHeight)) * 100;

  //   if (scrollPercentage > 95) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll); // Clean up
  // }, [handleScroll]);

  if (loading && !data?.length) return <LoadingScreen />;

  return (
    <Container maxWidth="false">
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", py: 8, gap: 8 }}
      >
        <Stack sx={{ gap: 3, alignItems: "center" }}>
          <Typography
            variant="h3"
            sx={{
              background: theme.palette.primaryGradient.main,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 600,
              fontSize: 45,
              textAlign: "center",
            }}
          >
            Creativio's Unmatched Features:
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.common.black,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Elevate Your Product Photography Like Never Before
          </Typography>
        </Stack>

        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              borderRadius: 10,
              background:
                "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",
              width: "100%",
              height: "calc(100% - 200px)",
            },
          }}
        >
          <Stack
            sx={{
              py: { xs: 3, sm: 8 },
              gap: 8,
              zIndex: 1,
              position: "relative",
            }}
          >
            <Stack sx={{ px: { xs: 1, sm: 5 } }}>
              {!data?.length ? (
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
                  >
                    No Data Found!
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  sx={{
                    gap: { xs: 4, sm: 8 },
                  }}
                >
                  {data?.map(
                    ({ id, image, feature_name, description }, index) => {
                      const flexDirection =
                        index % 2 === 0 ? "row" : "row-reverse";
                      return (
                        <Stack
                          key={id}
                          sx={{
                            flexDirection: { xs: "column", sm: flexDirection },
                            gap: { xs: 3, sm: 6 },
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: { xs: 2.5, sm: 5 },
                            bgcolor: theme.palette.common.white,
                            borderRadius: 6,
                            boxShadow:
                              "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                          }}
                        >
                          <Stack
                            sx={{
                              width: { xs: "100%", sm: "50%" },
                              flexDirection: {
                                xs: "column",
                                sm: flexDirection,
                              },
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={
                                image !== null && image !== ""
                                  ? image
                                  : defaultImage
                              }
                              sx={{
                                p: 0.6,
                                borderRadius: "50%",
                                // backgroundImage:
                                //   "linear-gradient(103deg, #563bff 0%, #7371fc 43.6%, #d94fd5 100%)",
                                // width: "calc(100% - 12.8px)",
                                background: theme.palette.primary.light,
                                maxWidth: {
                                  xs: "calc(100% - 4.8px)",
                                  sm: "200px",
                                },
                                // border: `4px solid ${theme.palette.primary.light}`,
                                height: "auto",
                              }}
                              alt={feature_name}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              justifyContent: "center",
                              gap: 3,
                              width: { xs: "100%", sm: "50%" },
                            }}
                          >
                            <Stack
                              sx={{
                                alignItems: "center",
                                flexDirection: "row",
                                gap: 2,
                              }}
                            >
                              <Stack
                                sx={{
                                  background:
                                    theme.palette.primaryGradient.main,
                                  height: { xs: "38px", sm: "45px" },
                                  width: { xs: "38px", sm: "45px" },
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  aspectRatio: "1/1",
                                  lineHeight: "unset",
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  sx={{
                                    color: theme.palette.common.white,
                                    fontWeight: 600,
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Stack>
                              <Typography
                                variant="h5"
                                sx={{ color: "#36379B", fontWeight: 600 }}
                              >
                                {feature_name}
                              </Typography>
                            </Stack>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                              sx={{ p: { fontSize: 18, color: "black" } }}
                            />
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              )}
            </Stack>

            <Stack sx={{ alignItems: "center", gap: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.primary.extraDark,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Revolutionize your product imagery with CreativioAI's
                unparalleled features.
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.primary.extraDark,
                  textAlign: "center",
                  maxWidth: 1200,
                }}
              >
                Whether you're a seasoned expert or a complete beginner, we have
                everything you need to create stunning, conversion-boosting
                product photos
              </Typography>
            </Stack>

            <Stack>
              <InstantAccess type="card" />
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Container>
  );
}
