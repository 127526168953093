import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import './styles.scss'
import { CircularProgress } from '@mui/material';

export function CButton({
    mainClass = "",
    addClass = "", // primary || secondary || dark || gradient || gradient2
    disabled = false,
    onClick = () => { },
    active = false,
    size = "",
    type = "button",
    children,
    tooltipContent,
    tooltipId,
    tooltipVariant = "light",
    loading = false
}) {
    return (
        <div className={`${mainClass}`} style={{ display: 'inline-block' }}>
            <button
                className={`d-flex align-items-center custom-button ${addClass} ${active ? 'active' : ''} ${size}`}
                disabled={loading || disabled}
                onClick={onClick}
                type={type}
                data-tooltip-id={tooltipId}
                data-tooltip-variant={tooltipVariant}
                data-tooltip-content={tooltipContent}
            >
                {children} {loading && <CircularProgress size={18} thickness={5} sx={{ color: "common.white" }} />}
            </button>
            <Tooltip id={tooltipId} style={{ zIndex: 1 }} />
        </div>
    )
}