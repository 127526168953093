import { AdminLayout } from "components/layouts/AdminLayout";
import { useContext, useRef } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useEffect } from "react";
import { getApi } from "utils/customNetwork";
import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { ReactComponent as IcoUser } from "assets/imgs/admin/dashboard/user.svg";
import { ReactComponent as IcoDollar } from "assets/imgs/admin/dashboard/dollar.svg";
import { ReactComponent as IcoRevenue } from "assets/imgs/admin/dashboard/revenue.svg";
import { ReactComponent as IcoProUsers } from "assets/imgs/admin/dashboard/pro_users.svg";
import { ReactComponent as IcoAgency } from "assets/imgs/admin/dashboard/agency.svg";
import { ReactComponent as IconPig } from "assets/imgs/modal/pig.svg";
import { ReactComponent as IconLogin } from "assets/imgs/admin/dashboard/login_black.svg";
// import { CDropdown } from "components/atoms/CDropdown";
import { CTextarea } from "components/atoms/CTextarea";
// import { CInput } from "components/atoms/CInput";
// import { CButton } from "components/atoms/CButton";
import useTable from "hooks/useTable";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import { UserModal } from "components/atoms/Modal/UserModal";
// import UserTable from "./userTable";
import { CreditHistoryModal } from "components/atoms/Modal/CreditHistoryModal";
import { varLicenseOptions } from "./variable";
import "./styles.scss";
import InfoCard from "components/molecules/admin/InfoCard";
import { CommonTable } from "components/Table";
import { Avatar, Box, Tooltip } from "@mui/material";
import getFullName from "utils/getFullName";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { LICENSE } from "utils/constants";
import moment from "moment";
import { commonSorting } from "utils/util";

const rowsPerPage = 10;

export function AdminDashboard() {
  const { setLoading, showNoti, checkToken, setUser, adminUser } =
    useContext(LayoutContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [creditConsumed, setCreditConsumed] = useState("");
  const [usersActivity, setUsersActivity] = useState("");
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(filteredUsers, page, rowsPerPage);
  // modal user area
  const [showUserModal, setShowUserModal] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [selUser, setSelUser] = useState();
  const [licenseOptions, setLicenseOptions] = useState(varLicenseOptions);
  const [selectedLicense, setSelectedLicense] = useState("license");

  const tableRef = useRef();

  const getDashboardData = async (showLoading = true) => {
    if (showLoading) setLoading(true);

    try {
      checkToken();
      const data = await getApi("/admin/dashboard", "POST", {});
      const statistics = await getApi("/admin/users/statistic", "GET");
      setData({ ...data, ...statistics.data });
      setUsers(data.users);
      // onSearch(data.users);
    } catch (err) {
      showNoti("error", err);
    }
    if (showLoading) setLoading(false);
  };

  const onSearchLicense = (orgUsers) => {
    setPage(1);

    const searchLicense = selectedLicense.toLowerCase();
    const filteredLicense = orgUsers.filter((user) => {
      if (searchLicense !== "license") {
        if (Array.isArray(user.license)) {
          const licenseData = user.license.some((license) =>
            license.toLowerCase().includes(searchLicense)
          );
          return licenseData;
        } else {
          const license = user.license.toLowerCase();
          return license.includes(searchLicense);
        }
      }
    });

    if (searchLicense !== "license") {
      setFilteredUsers(filteredLicense);
    } else {
      setFilteredUsers(orgUsers);
    }
  };

  // const onSearch = (orgUsers) => {
  //   setPage(1);
  //   const filtered = orgUsers.filter((user) => {
  //     const searchTerm = keyword.toLowerCase();
  //     const name = (user.first_name + " " + user.last_name).toLowerCase();
  //     const email = user.email.toLowerCase();
  //     const status = user.status.toLowerCase();
  //     const source = user.source.toLowerCase();
  //     if (Array.isArray(user.license)) {
  //       const licenseMatch = user.license.some((license) =>
  //         license.toLowerCase().includes(searchTerm)
  //       );
  //       return (
  //         name.includes(searchTerm) ||
  //         email.includes(searchTerm) ||
  //         licenseMatch ||
  //         status.includes(searchTerm) ||
  //         source.includes(searchTerm)
  //       );
  //     } else {
  //       const license = user.license.toLowerCase();
  //       return (
  //         name.includes(searchTerm) ||
  //         email.includes(searchTerm) ||
  //         license.includes(searchTerm) ||
  //         status.includes(searchTerm)
  //       );
  //     }
  //   });

  //   setFilteredUsers(filtered);
  // };

  const getActiveMenu = (arr) => {
    // if(arr && arr.length() === 0) return null
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };
  const handleCreateUser = () => {
    setSelUser();
    setShowUserModal(true);
  };

  const onCloseUserModal = () => {
    setSelUser();
    setShowUserModal(false);
  };
  const handleTblEdit = (item) => {
    setSelUser(item);
    setShowUserModal(true);
  };
  const handleTblCredit = (item) => {
    setSelUser(item);
    setShowCreditModal(true);
  };
  const handleTblUserLogin = async (item) => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/getUserToken", "POST", {
        user_id: item.id,
      });

      const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins
      setUser({ user: data.user, token: data.token, expired });
      //redirect to home
      window.open("/dashboard", "_blank", "noreferrer");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);
    try {
      // Ensure that checkToken, getApi, showNoti, and getDashboardData are defined
      checkToken();
      await getApi("/admin/deleteUser", "POST", { user_id: item.id });
      showNoti("success", "Delete user successfully");
      await getDashboardData(false);
      await handleResetTableState();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleLicenseRatio = (id, index, value) => {
    switch (id) {
      case "license_option":
        const customlicenseOptions = [...licenseOptions];
        customlicenseOptions.forEach((el) => (el.checked = false));
        customlicenseOptions[index].checked = value;
        setLicenseOptions(customlicenseOptions);
        setSelectedLicense(getActiveMenu(customlicenseOptions).id);
        break;
      default:
    }
  };

  useEffect(() => {
    if (adminUser) {
      getDashboardData();
    }
  }, []);

  // useEffect(() => {
  //   onSearch(users);
  // }, [keyword]);

  const handleResetTableState = async () => {
    if (tableRef.current) {
      tableRef.current.resetState();
    }
  };

  useEffect(() => {
    onSearchLicense(users);
  }, [selectedLicense]);

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Edit">
          <ICONS.Edit
            onClick={() => handleTblEdit(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Credit Histories">
          <IconPig
            onClick={() => handleTblCredit(item)}
            style={{ width: 24, height: 22, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Direct Login">
          <IconLogin
            onClick={() => handleTblUserLogin(item)}
            style={{ width: 24, height: 24, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => {
              handleTblDelete(item);
            }}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const adminUserColumns = [
    {
      id: "role",
      fieldName: "role",
      label: "Role",
      align: "center",
      cellStyle: {
        minWidth: 70,
      },
    },
    {
      id: "profile_img",
      fieldName: "profile_img",
      label: "Photo",
      render: ({ rowData }) => (
        <Avatar
          alt={rowData.first_name || rowData.email}
          src={rowData.profile_img}
          style={{ margin: "auto" }}
        />
      ),
      align: "center",
      cellStyle: {
        minWidth: 70,
      },
      sorting: false,
    },
    {
      id: "name",
      fieldName: "name",
      label: "Name",
      render: ({ rowData }) => getFullName(rowData),
      customSort: (dataA, dataB, order) => {
        return commonSorting(getFullName(dataA), getFullName(dataB), order);
      },
      align: "center",
    },
    {
      id: "email",
      fieldName: "email",
      label: "Email",
      align: "center",
    },
    {
      id: "license",
      fieldName: "license",
      label: "License Type",
      render: ({ rowData }) => rowData.license.join(", "),
      align: "center",
      sorting: false,
    },
    {
      id: "source",
      fieldName: "source",
      label: "Source",
      render: ({ rowData }) =>
        rowData.source === "paddle" ? "Paddle" : "JvZoo",
      align: "center",
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          dataA.source === "paddle" ? "Paddle" : "JvZoo",
          dataB.source === "paddle" ? "Paddle" : "JvZoo",
          order
        );
      },
    },

    {
      id: "credits",
      fieldName: "credits",
      label: "Credits Usage",
      render: ({ rowData }) =>
        rowData.license.includes(LICENSE.PROFESSIONAL)
          ? "Unlimited"
          : rowData.credits,
      align: "center",
      sorting: false,
    },
    {
      id: "status",
      fieldName: "status",
      label: "Status",
      align: "center",
    },
    {
      id: "created_at",
      fieldName: "created_at",
      label: "Created At",
      render: ({ rowData }) =>
        moment(rowData?.created_at).format("DD-MM-YYYY hh:mm A"),
      align: "center",
      cellStyle: {
        minWidth: 130,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.created_at).format("DD-MM-YYYY hh:mm A"),
          moment(dataB?.created_at).format("DD-MM-YYYY hh:mm A"),
          order
        );
      },
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        minWidth: 200,
      },
      sorting: false,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-dashboard body-container">
        <div className="head">
          <Slide className="flex1" triggerOnce={true}>
            <h1>
              <span>Dashboard</span> Statistics
            </h1>
          </Slide>
        </div>
        <div className="card-area mt-15">
          <InfoCard title="Total Revenue" Icon={IcoDollar} value="$5033" />
          <InfoCard
            title="Stability Balance"
            Icon={IcoDollar}
            value={data && data?.stability_balance?.toFixed(2)}
          />
          <InfoCard
            title="Runpod Balance"
            Icon={IcoDollar}
            value={data && data?.stability_balance?.toFixed(2)}
          />
          <InfoCard title="MRR Revenue" Icon={IcoRevenue} value="$12,467" />
          <InfoCard
            title="Total Users"
            Icon={IcoUser}
            value={data.totalUsers}
          />
        </div>
        <div className="card-area mt-15">
          <InfoCard
            title="Pro Users"
            Icon={IcoProUsers}
            value={data.proUsers}
          />
          <InfoCard
            title="Agency Users"
            Icon={IcoAgency}
            value={data.agencyUsers}
          />
          <InfoCard
            title="Animation Users"
            Icon={IcoAgency}
            value={data.animationUsers}
          />
          <InfoCard
            title="Starter Users"
            Icon={IcoAgency}
            value={data.starterUsers}
          />
          <InfoCard
            title="Business Users"
            Icon={IcoAgency}
            value={data.businessUsers}
          />
        </div>
        <div className="middle-area mt-15">
          <CTextarea
            placeholder="Credits Consumed"
            onChange={(e) => setCreditConsumed(e.target.value)}
            rows={5}
            addClass="product mt-5"
            active={true}
            value={creditConsumed}
          ></CTextarea>
          <CTextarea
            placeholder="Users Activities"
            onChange={(e) => setUsersActivity(e.target.value)}
            rows={5}
            addClass="product mt-5"
            active={true}
            value={usersActivity}
          ></CTextarea>
        </div>

        <div className="btn-group mt-35">
          {/* <div className="search-area">
            <CInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              addClass="full text-white"
              placeholder="Enter text for search user"
            />
          </div> */}
          {/* <div className="search-area license-section">
            <CDropdown
              addClass="full"
              id="license_option"
              action={handleLicenseRatio}
              activeMenu={getActiveMenu(licenseOptions)}
              menu={licenseOptions}
            />
          </div> */}
          {/* <div className="btn-mode">
            <CButton
              addClass="gradient"
              active={true}
              onClick={handleCreateUser}
            >
              Create User
            </CButton>
          </div> */}
        </div>

        {/* <UserTable
          slice={slice}
          range={range}
          page={page}
          handleTblEdit={handleTblEdit}
          handleTblCredit={handleTblCredit}
          handleTblUserLogin={handleTblUserLogin}
          handleTblDelete={handleTblDelete}
          setPage={setPage}
        /> */}

        <CommonTable
          columns={adminUserColumns}
          rows={users}
          tableSearchEnable={true}
          searchableFields={[
            "role",
            "name",
            "email",
            "source",
            "credits",
            "status",
          ]}
          addHeaderButton={true}
          addHeaderButtonName={"Create User"}
          addHeaderButtonOnClick={handleCreateUser}
          tableSelectBox={true}
          tableSelectBoxName={"license"}
          tableSelectBoxOptions={varLicenseOptions?.map((item) => {
            return { id: item.id, name: item.label, value: item.id };
          })}
          ref={tableRef}
        />

        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          itemModule="User"
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemName={
            itemToDelete
              ? itemToDelete.first_name + " " + itemToDelete.last_name
              : ""
          }
        />
      </div>
      {showUserModal && (
        <UserModal
          show={showUserModal}
          user={selUser}
          onClose={onCloseUserModal}
          onHandleUpdate={() => getDashboardData(false)}
        />
      )}
      {showCreditModal && (
        <CreditHistoryModal
          show={showCreditModal}
          user={selUser}
          onClose={() => {
            setSelUser();
            setShowCreditModal(false);
          }}
        />
      )}
    </AdminLayout>
  );
}
