import { DashboardLayout } from "components/layouts/DashboardLayout";
import { Slide } from "react-awesome-reveal";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./styles.scss";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ReactComponent as ChartUp } from "assets/imgs/chartup.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { CButton } from "components/atoms/CButton";
import { CDropdown } from "components/atoms/CDropdown";
import { CInput } from "components/atoms/CInput";
import { ReactComponent as EditImg } from "assets/imgs/create/edit.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { ReactComponent as HeartImg } from "assets/imgs/create/heart.svg";
import { ReactComponent as HeartFillImg } from "assets/imgs/create/heart_fill.svg";
import { ReactComponent as ViewImg } from "assets/imgs/create/view.svg";
import { ReactComponent as ArrowForward } from "assets/imgs/editor/arrow_forward.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/editor/arrow_back.svg";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import Upload from "assets/imgs/upload.svg";
import { useRef } from "react";
import BrushCanvas from "components/atoms/ImageEditor/BrushCanvas";
import CImage from "components/atoms/CImage";
import {
  checkPermission,
  getLicense,
  isPossible,
  showLicense,
} from "utils/util";

import { convertHeicImage } from "pages/Edit/utils";
import useContent from "hooks/studio/useContent";
import { LICENSE } from "utils/constants";
import { Tooltip } from "react-tooltip";
import { Stack } from "@mui/material";
import useWindowSize from "hooks/useWindowSize";
import { theme } from "utils/landingTheme";

export function AIBKRemover() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const brushCanvasRef = useRef(null);

  const [searchParams] = useSearchParams();
  const [layers, setLayers] = useState([]);
  const [backupLayers, setBackupLayers] = useState([]);
  const [redoStep, setRedoStep] = useState(0);

  const { showNoti, user, setLoading, showImageModal, checkToken } =
    useContext(LayoutContext);
  const [stage, setStage] = useState("upload"); // upload ||  edit
  const [activeTrend, setActiveTrend] = useState("trending"); // trending || new || top
  const [activeMode, setActiveMode] = useState("all"); // all | upscaled
  const [viewOptions, setViewOptions] = useState([
    {
      key: 1,
      id: "image-to-image",
      icon: null,
      color: "white",
      checked: false,
      label: "Product Photoshoot",
    },
    {
      key: 2,
      id: "all",
      icon: null,
      color: "white",
      checked: true,
      label: "All Images",
    },
    {
      key: 3,
      id: "image-animation",
      icon: null,
      color: "white",
      checked: false,
      label: "Image Animations",
    },
  ]);
  const [activeViewOpt, setActiveViewOpt] = useState("all"); // all | upscaled
  const [keyword, setKeyword] = useState("");
  const [trendImgs, setTrendImgs] = useState(null);
  const [selImage, setSelImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLast, setIsLast] = useState(false);

  const [newImage, setNewImage] = useState(null); // new image - file
  const [imageType, setImageType] = useState("url"); // url | upload
  const [orgImageWidth, setOrgImageWidth] = useState(1024);
  const [orgImageHeight, setOrgImageHeight] = useState(1024);
  const [imageWidth, setImageWidth] = useState(1024);
  const [imageHeight, setImageHeight] = useState(1024);

  const { onImportImage } = useContent();

  const checkLicense = (license) => {
    const isValid = isPossible(getLicense(user?.user), license);

    if (!isValid) {
      let missingLicense = "";
      for (const permissionSet of license) {
        for (const prmsn of permissionSet) {
          if (!user?.user?.license.includes(prmsn)) {
            missingLicense = [prmsn];
            if (
              missingLicense.includes(LICENSE.BUSINESS) ||
              missingLicense.includes(LICENSE.STARTER)
            ) {
              missingLicense = [LICENSE.BUSINESS, LICENSE.STARTER];
            }
            break;
          }
        }
        if (missingLicense) break;
      }

      const formatLicense = showLicense(missingLicense, true);

      showNoti(
        "info",
        `You need to upgrade to a ${formatLicense} license to unlock this feature.`
      );
    }

    return isValid;
  };

  const handleEdit = (image) => {
    // if (user?.user?.license && !user.user.license.includes(LICENSE.PROFESSIONAL)) {
    //   showNoti(
    //     "info",
    //     `You need to upgrade to a Pro license to unlock this feature.`
    //   ); return;
    // }
    if (
      checkLicense([
        [LICENSE.PROFESSIONAL, LICENSE.BUSINESS],
        [LICENSE.PROFESSIONAL, LICENSE.STARTER],
      ])
    ) {
      onImportImage(image);
    }
  };

  const onSearch = () => {
    getViewImages(activeTrend, activeViewOpt, keyword, 1);
  };
  const handleLike = async (image_id, liked) => {
    try {
      checkToken();
      await getApi("/like", "POST", {
        image_id,
        is_like: !liked ? "yes" : "no",
      });
      const tmp = [...trendImgs];
      tmp.forEach((obj) => {
        if (obj.id === image_id) {
          obj.liked = !liked;
          if (!liked) obj.like_ct = Number(obj.like_ct) + 1;
          else obj.like_ct = Number(obj.like_ct) - 1;
        }
      });

      setTrendImgs(tmp);
    } catch (err) {
      showNoti("error", err);
    }
  };
  const handleDownload = (imageUrl, fileName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
        showNoti("error", "Error downloading image");
      });
  };
  const onClickImageMode = (tp) => {
    if (tp !== activeTrend) {
      getViewImages(tp, activeViewOpt, keyword, 1);
    }
    setActiveTrend(tp);
  };
  const gotoEditor = () => {
    if (selImage) {
      onUploadImage("url", selImage, orgImageWidth, orgImageHeight);
    } else {
      showNoti("error", "Please select an image");
    }
  };

  const { width } = useWindowSize();

  const PAGE_SIZE = useMemo(() => {
    if (!width) return null;

    const { md, lg, xl, xxl, xl3, xl4 } = theme?.breakpoints?.values;

    if (width > xl4) {
      return 8;
    } else if (width > xl3) {
      return 7;
    } else if (width > xxl) {
      return 6;
    } else if (width > xl) {
      return 5;
    } else if (width > lg) {
      return 3;
    } else if (width > md) {
      return 2;
    } else {
      return 1;
    }
  }, [width]);

  const onReadMore = (arrow) => {
    let curPage = currentPage;
    if (arrow === "forward") {
      if (trendImgs.length >= (currentPage + 1) * PAGE_SIZE) {
        curPage++;
      } else {
        if (isLast) {
          // call api
          curPage++;
          getViewImages(activeTrend, activeViewOpt, keyword, curPage);
        } else {
          return null;
        }
      }
    } else {
      if (curPage === 1) return;
      curPage--;
    }
    setCurrentPage(curPage);
  };
  const getViewImages = useCallback(
    async (
      trend = "trending",
      viewOption = "all",
      searchString = keyword,
      curPage = 1
    ) => {
      try {
        setLoading(true);
        checkToken();
        const data = await getApi("/getImages", "POST", {
          page_size: PAGE_SIZE,
          feed_type: "personal",
          view_category: trend,
          user_id: user.user.id,
          keyword: searchString,
          generate_type: viewOption,
          page: curPage,
        });
        setCurrentPage(curPage);
        if (curPage > 1) {
          setTrendImgs([...trendImgs, ...data.images]);
        } else {
          setTrendImgs(data.images);
        }
        setIsLast(data.last);
      } catch (err) {
        showNoti("error", err);
      }
      setLoading(false);
    },
    [
      PAGE_SIZE,
      checkToken,
      keyword,
      setLoading,
      showNoti,
      trendImgs,
      user.user.id,
    ]
  );
  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "view_option":
        const customOptions = [...viewOptions];
        customOptions.forEach((el) => (el.checked = false));
        customOptions[index].checked = value;
        getViewImages(activeTrend, customOptions[index].id, keyword, 1);
        setActiveViewOpt(customOptions[index].id);
        setViewOptions(customOptions);
        break;
      default:
        break;
    }
  };
  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };
  const selectImage = (image) => {
    setSelImage(image.image);
    setResultImage(image.image);
    setOrgImageWidth(image.width);
    setOrgImageHeight(image.height);
    setImageWidth(image.width);
    setImageHeight(image.height);
  };
  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  const parseReader = (reader, file) => {
    reader.onload = (event) => {
      setSelImage(reader.result);
      setResultImage(reader.result);
      const imageElement = new Image();
      imageElement.src = event.target.result;
      imageElement.onload = function () {
        onUploadImage("upload", file, imageElement.width, imageElement.height);
      };
    };
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    if (file.type === "" || file.type === "image/heic") {
      const blob = await convertHeicImage(file);
      setNewImage(blob);
      parseReader(reader, blob);
      reader.readAsDataURL(blob);
      return;
    }

    setNewImage(file);
    parseReader(reader, file);
    reader.readAsDataURL(file);
  };

  const onUploadImage = async (type, newImage, width, height) => {
    const formData = new FormData();
    formData.append("type", type);
    if (type === "upload") {
      formData.append("product", newImage);
    } else {
      formData.append("url", newImage);
    }
    formData.append("width", width);
    formData.append("height", height);
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/removeBackgroundImg", "POST", formData);
      const img = data.url;
      setSelImage(img);
      setResultImage(img);
      setOrgImageWidth(data.width);
      setOrgImageHeight(data.height);
      const { newWidth, newHeight } = adjustSizes({
        height: data.height,
        width: data.width,
      });

      setImageWidth(newWidth);
      setImageHeight(newHeight);
      setImageType("url");
      setStage("edit");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const adjustSizes = ({ height, width }) => {
    let newWidth = width;
    let newHeight = height;
    const aspectRatio = width / height;

    if (width > 768) {
      newWidth = 768;
      newHeight = 768 / aspectRatio;
    }

    return { newHeight, newWidth };
  };

  const onEditImage = async () => {
    if (!brushCanvasRef.current) return resultImage;
    let result = resultImage;

    const base64image = brushCanvasRef.current.getImage();

    const formData = new FormData();
    formData.append("type", imageType);
    if (imageType === "url") {
      formData.append("url", selImage);
    } else {
      formData.append("image", newImage);
    }
    formData.append("width", imageWidth);
    formData.append("height", imageHeight);
    formData.append("mask_image", base64image);

    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/removeSubBkImg", "POST", formData);
      const img = data.url;
      setImageType("url");
      setResultImage(img);
      setImageWidth(data.width);
      setImageHeight(data.height);
      result = img;
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    return result;
  };

  const renderUpload = () => {
    return (
      <div className="section-upload">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>AI Remove</span> Background
            </h1>
          </Slide>
        </div>
        <div className="image-upload-area mt-10">
          <input
            type="file"
            // accept="image/*"
            accept=".png, .webp, .jpg, .jpeg"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <div className="custom-upload" onClick={onImageUpload}>
            <p className="lbl-bold">
              Use a Brush to remove objects or paint in new ones
            </p>
            <img src={Upload} />
            <p>To Get Started, Select a Sample asset or upload an image</p>
          </div>
        </div>
      </div>
    );
  };
  const renderFeed = () => {
    return (
      <div id="choose_imaage">
        <div className="section2">
          <Slide>
            <h1>
              <span>Personal</span> Feed
            </h1>
          </Slide>
          <div className="btn-actions">
            <CButton
              addClass="gradient"
              active={activeTrend === "trending"}
              onClick={() => onClickImageMode("trending")}
            >
              Trending
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "new"}
              onClick={() => onClickImageMode("new")}
            >
              <Stars className="btn-icon" />
              New
            </CButton>
            <CButton
              addClass="gradient"
              active={activeTrend === "top"}
              onClick={() => onClickImageMode("top")}
            >
              <ChartUp className="btn-icon" />
              Top
            </CButton>
            <CDropdown
              id="view_option"
              addClass="w-220"
              showIcon={false}
              action={handleOptRatio}
              activeMenu={getActiveMenu(viewOptions)}
              menu={viewOptions}
            />
          </div>
          <div className="btn-group mt-15">
            <div className="search-area">
              <CInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                addClass="full text-white"
              />
              <CButton addClass="gradient" active={true} onClick={onSearch}>
                Search
              </CButton>
            </div>
            <div className="btn-mode">
              <CButton
                addClass="gradient"
                active={activeMode === "all"}
                onClick={() => setActiveMode("all")}
              >
                All
              </CButton>
              <CButton
                addClass="gradient"
                active={activeMode === "upscaled"}
                onClick={() => setActiveMode("upscaled")}
              >
                Upscaled
              </CButton>
            </div>
          </div>
          <div className="trend-area mt-25">
            {trendImgs && trendImgs.length !== 0 ? (
              <div
                onClick={() => onReadMore("back")}
                className="arrow-action-btn back"
              >
                <ArrowBack />
              </div>
            ) : null}

            <Stack
              sx={{
                flex: 1,
                flexDirection: "row",
                gap: 2,
              }}
            >
              {trendImgs && trendImgs.length !== 0 ? (
                trendImgs.map((k, index) => {
                  const pageMin = PAGE_SIZE * (currentPage - 1);
                  const pageMax = PAGE_SIZE * currentPage;
                  if (index >= pageMin && index < pageMax) {
                    return (
                      <div
                        className={`result-card ${
                          selImage === k.image ? "active" : ""
                        }`}
                        key={index}
                        style={{ width: `${100 / PAGE_SIZE}%` }}
                      >
                        {/* <img src={k.image} alt={k.seed} onClick={() => selectImage(k)} /> */}
                        <CImage
                          src={k.image}
                          alt={k.seed}
                          onClick={() => selectImage(k)}
                        />
                        <div className="card-action-bar">
                          <div className="first-part">
                            <ViewImg
                              data-tooltip-id={`tip-view-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content="View"
                              className="action-btn"
                              onClick={() => showImageModal(k, trendImgs)}
                            />
                          </div>
                          <div className="last-part">
                            <EditImg
                              data-tooltip-id={`tip-edit-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content="Edit"
                              className="action-btn"
                              onClick={() => handleEdit(k, trendImgs)}
                            />
                            <DownloadImg
                              data-tooltip-id={`tip-download-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content="Download"
                              className="action-btn"
                              onClick={() =>
                                handleDownload(k.image, "download.png")
                              }
                            />
                            {k.liked ? (
                              <div className="ttt">
                                <HeartFillImg
                                  data-tooltip-id={`tip-unlike-${index}`}
                                  data-tooltip-variant="light"
                                  data-tooltip-content="Unlike"
                                  className="action-btn"
                                  onClick={() => handleLike(k.id, k.liked)}
                                />
                                {k.like_ct}
                              </div>
                            ) : (
                              <div className="ttt">
                                <HeartImg
                                  data-tooltip-id={`tip-like-${index}`}
                                  data-tooltip-variant="light"
                                  data-tooltip-content="Like"
                                  className="action-btn"
                                  onClick={() => handleLike(k.id, k.liked)}
                                />
                                {k.like_ct}
                              </div>
                            )}
                          </div>
                        </div>
                        <Tooltip id={`tip-view-${index}`} />
                        <Tooltip id={`tip-edit-${index}`} />
                        <Tooltip id={`tip-download-${index}`} />
                        <Tooltip id={`tip-like-${index}`} />
                        <Tooltip id={`tip-unlike-${index}`} />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <div className="no-records-message">
                  <p>No records found.</p>
                </div>
              )}
            </Stack>

            {trendImgs && trendImgs.length !== 0 ? (
              <div
                onClick={() => onReadMore("forward")}
                className="arrow-action-btn forward"
              >
                <ArrowForward />
              </div>
            ) : null}
          </div>
          {trendImgs && trendImgs.length !== 0 ? (
            <div className="text-center mt-20 mb-20">
              <CButton
                addClass="gradient active py-22i"
                onClick={gotoEditor}
                size="medium"
              >
                Continue With Selected Image
              </CButton>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const renderEditor = () => {
    return (
      <BrushCanvas
        maskType="white"
        ref={brushCanvasRef}
        mainCaption="Magic"
        subCaption="Remover Tool"
        image={{
          src: resultImage,
          width: imageWidth,
          height: imageHeight,
        }}
        orgImage={{
          src: selImage,
          width: orgImageWidth,
          height: orgImageHeight,
        }}
        history={{
          layers,
          setLayers,
          backupLayers,
          setBackupLayers,
          redoStep,
          setRedoStep,
        }}
        onRemove={onEditImage}
      />
    );
  };
  const checkParam = useCallback(() => {
    const paramValue = searchParams.get("show-image");
    if (paramValue) {
      const decode_x = atob(paramValue);
      const parameters = JSON.parse(decode_x);
      setStage("edit");
      setResultImage(parameters.image);
      setOrgImageWidth(parameters.width);
      setOrgImageHeight(parameters.height);
      setSelImage(parameters.image);
      setImageWidth(parameters.width);
      setImageHeight(parameters.height);
    } else {
      getViewImages();
    }
  }, [getViewImages, searchParams]);
  useEffect(() => {
    if (!checkPermission(user)) {
      showNoti("error", "Permission denied. Please contact the administrator.");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      if (PAGE_SIZE) {
        checkParam();
      }
    }
  }, [PAGE_SIZE]);
  return (
    <DashboardLayout
      page="my-feed"
      caption="Personal Feed"
      message="Welcome to Creativio AI"
      license={[[LICENSE.BUSINESS], [LICENSE.STARTER]]}
    >
      <div className="magic-remover">
        {stage === "upload" && renderUpload()}
        {stage === "upload" && renderFeed()}
        {stage === "edit" && renderEditor()}
      </div>
    </DashboardLayout>
  );
}
