import { ReactComponent as ScaleImg } from "assets/imgs/create/scale.svg";
import { ReactComponent as EditImg } from "assets/imgs/create/edit.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { ReactComponent as HeartImg } from "assets/imgs/create/heart.svg";
import { ReactComponent as HeartFillImg } from "assets/imgs/create/heart_fill.svg";
import { ReactComponent as ViewImg } from "assets/imgs/create/view.svg";
import { ReactComponent as IcoDelete } from "assets/imgs/action/icon_delete.svg";
import { Tooltip } from "react-tooltip";
import "./styles.scss";
import CVideo from "../CVideo";
import getFullName from "utils/getFullName";

export function VideoCard({
  video = null,
  videos = [],
  onView = null,
  onUpScale = null,
  onEdit = null,
  onDownload = null,
  onLike = null,
  onDelete = null,
  deleteVideo = false,
}) {
  if (!video) return null;

  const getVideoPath = (video) => {
    // if (video.smallestVideoPath) return video.smallestVideoPath
    if (video.smallVideoPath) return video.smallVideoPath;

    return video.video;
  };

  const getVideoPlaceholder = (video) => {
    if (video.smallestVideoPath) return video.smallestVideoPath;
    if (video.smallVideoPath) return video.smallVideoPath;

    return video.video;
  };

  const formatTooltipId = (name) => {
    return `${name}-${video.id}`;
  };

  return (
    <div className="result-card">
      <CVideo
        key={video.id}
        src={getVideoPath(video)}
        alt="Video"
        placeholder={getVideoPlaceholder(video)}
        style={{ height: "100%", width: "100%" }}
      />
      <div className="card-action-bar">
        <div className="first-part">
          {onView && (
            <ViewImg
              data-tooltip-id={formatTooltipId("tip-view")}
              data-tooltip-variant="light"
              data-tooltip-content="View"
              className={`action-btn${video.video === null ? " disabled" : ""}`}
              onClick={() => onView(video, videos)}
            />
          )}
        </div>
        <div className="last-part">
          {onUpScale && (
            <ScaleImg
              data-tooltip-id={formatTooltipId("tip-scale")}
              data-tooltip-variant="light"
              data-tooltip-content="Upscale"
              className={`action-btn${video.video === null ? " disabled" : ""}`}
              onClick={() => onUpScale(video)}
            />
          )}
          {onEdit && (
            <EditImg
              data-tooltip-id={formatTooltipId("tip-edit")}
              data-tooltip-variant="light"
              data-tooltip-content="Edit"
              className={`action-btn${video.video === null ? " disabled" : ""}`}
              onClick={() => onEdit(video, videos)}
            />
          )}
          {onDownload && (
            <DownloadImg
              data-tooltip-id={formatTooltipId("tip-download")}
              data-tooltip-variant="light"
              data-tooltip-content="Download"
              className={`action-btn${video.video === null ? " disabled" : ""}`}
              onClick={() => onDownload(video.video, "animation.mp4")}
            />
          )}
          {deleteVideo && onDelete && (
            <IcoDelete
              data-tooltip-id={formatTooltipId("tip-delete")}
              data-tooltip-variant="light"
              data-tooltip-content="Delete"
              className="action-btn"
              onClick={() => onDelete(video.id)}
            />
          )}
          {onLike &&
            (video.liked ? (
              <div className="liker">
                <HeartFillImg
                  data-tooltip-id={formatTooltipId("tip-unlike")}
                  data-tooltip-variant="light"
                  data-tooltip-content="Unlike"
                  className={`action-btn${
                    video.video === null ? " disabled" : ""
                  }`}
                  onClick={() => onLike(video.id, video.liked)}
                />
                {video.like_ct}
              </div>
            ) : (
              <div className="liker">
                <HeartImg
                  data-tooltip-id={formatTooltipId("tip-like")}
                  data-tooltip-variant="light"
                  data-tooltip-content="Like"
                  className={`action-btn${
                    video.video === null ? " disabled" : ""
                  }`}
                  onClick={() => onLike(video.id, video.liked)}
                />
                {video.like_ct}
              </div>
            ))}
        </div>
      </div>
      <div className="created-user">{getFullName(video.user)}</div>
      <Tooltip id={formatTooltipId("tip-view")} />
      <Tooltip id={formatTooltipId("tip-scale")} />
      <Tooltip id={formatTooltipId("tip-edit")} />
      <Tooltip id={formatTooltipId("tip-download")} />
      <Tooltip id={formatTooltipId("tip-like")} />
      <Tooltip id={formatTooltipId("tip-unlike")} />
      <Tooltip id={formatTooltipId("tip-delete")} />
    </div>
  );
}
