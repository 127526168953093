// routes
import { BrowserRouter as Router } from "react-router-dom";
import { PublicRoutes } from "routes";
import { Provider } from "react-redux";

// ui
import { AppLayout } from "components/layouts";
import LayoutContextContainer from "components/contexts/LayoutContextContainer";
import "react-notifications/lib/notifications.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
//store
import { store } from "./store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { theme } from "utils/landingTheme";
import { ThemeProvider } from "@mui/material";

function App() {
  return (
    <Provider store={store}>
      <AppLayout>
        <GoogleOAuthProvider clientId="126384551962-8vcf3b4tmt1or59p1pg2ehj4e9et45o0.apps.googleusercontent.com">
          <Router>
            <LayoutContextContainer>
              <ThemeProvider {...{ theme }}>
                <PublicRoutes />
              </ThemeProvider>
            </LayoutContextContainer>
          </Router>
        </GoogleOAuthProvider>
      </AppLayout>
    </Provider>
  );
}

export default App;
