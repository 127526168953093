import { Box } from "@mui/material";
import React from "react";

const LogoIcon = ({ width = 75, height = 75, ...props }) => {
  const svg = `<svg
  width="${width}"
  height="${height}"
  viewBox="0 0 122 125"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M80.2881 44.7679L63.3543 28.0923C52.2172 17.1251 34.1537 17.1363 23.0166 28.1035L43.1911 8.21418C48.5315 2.95531 55.8116 0 63.3543 0C70.6573 0 77.9603 2.74181 83.5288 8.22542C101.866 26.2831 102.779 27.1821 121.105 45.2286C109.329 56.8251 91.5735 55.8812 80.2881 44.7679Z"
    fill="url(#paint0_linear_67_400)"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M28.5388 60.927C17.4017 71.8942 17.4131 89.6822 28.5388 100.649L8.3414 80.7826C3.00108 75.5237 0 68.3546 0 60.927C0 53.7353 2.79568 46.5437 8.36422 41.0601L18.3146 31.2615C28.9267 20.8112 46.8761 19.665 58.6522 31.2391L67.3245 39.7679C59.1315 37.6441 52.399 37.4306 45.9747 43.757L28.5502 60.9157L28.5388 60.927Z"
    fill="#7371FC"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M63.3642 96.6718C74.5013 107.639 92.5648 107.628 103.702 96.6718L83.5273 116.561C78.187 121.82 70.9068 124.775 63.3642 124.787C56.0612 124.787 48.7582 122.034 43.1896 116.55L33.2393 106.751C22.6271 96.301 21.4632 78.6253 33.2165 67.0401L41.8774 58.5C39.7207 66.5681 39.5039 73.1979 45.9283 79.5243L63.3528 96.683L63.3642 96.6718Z"
    fill="url(#paint1_linear_67_400)"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M80.7774 79.5343C74.3873 85.8269 67.5749 85.6134 59.3477 83.4672L68.0656 92.0522C79.2141 103.031 97.289 102.986 108.403 92.0409C122.656 78.006 103.645 96.7492 121.115 79.5455C109.978 68.5783 91.9145 68.567 80.7774 79.5343Z"
    fill="#7371FC"
  />
  <defs>
    <linearGradient
      id="paint0_linear_67_400"
      x1="23.0166"
      y1="26.7612"
      x2="132.009"
      y2="30.8881"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#563BFF" />
      <stop offset="0.435994" stop-color="#7371FC" />
      <stop offset="1" stop-color="#D94FD5" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_67_400"
      x1="24.835"
      y1="91.6432"
      x2="112.542"
      y2="93.7992"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#563BFF" />
      <stop offset="0.435994" stop-color="#7371FC" />
      <stop offset="1" stop-color="#D94FD5" />
    </linearGradient>
  </defs>
</svg>`;
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: svg }}
      {...{ width, height }}
      {...props}
    />
  );
};

export default LogoIcon;
