import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DefaultImage from "assets/imgs/default/blog_default.jpg";
import "./styles.scss";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { lineClamp } from "utils/util";
import moment from "moment";
import { PAGES } from "utils/urls";
import LoadingScreen from "components/LandingComponents/loading-screen";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { getLandingBlogs } from "store/slices/landingSite/reducer";
import { useSelector } from "react-redux";

export function Blogs() {
  // const [blogPosts, setBlogPosts] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const postsPerPage = 10;
  // const [isLast, setIsLast] = useState(false);

  // const [loading, setLoading] = useState();

  // const loadPosts = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const data = await getApi("/getBlogsList", "POST", {
  //       page: currentPage,
  //       page_size: postsPerPage,
  //     });
  //     console.log(data);
  //     if (data.records !== undefined && data.records.length > 0) {
  //       setBlogPosts((prevPosts) => [...prevPosts, ...data.records]);
  //       setIsLast(data.last);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching blog posts:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage]);

  // useEffect(() => {
  //   setBlogPosts([]);
  //   setCurrentPage(1);
  // }, []);

  // useEffect(() => {
  //   loadPosts();
  // }, [currentPage, loadPosts]);

  // const handleScroll = throttle((e) => {
  //   if (isLast) return;

  //   const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
  //   const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

  //   if (scrollPercentage > 95) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  const [fetchData] = useDispatchWithAbort(getLandingBlogs);

  const {
    blogs: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({ page: 1, page_size: 20 });
  }, [fetchData]);

  if (loading && !data?.length) return <LoadingScreen />;

  return (
    <Stack sx={{}}>
      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Stack sx={{ gap: 10 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              Welcome to Our Blog:
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Stay Updated with the Latest Trends, Tips, and Stories!
            </Typography>
          </Stack>

          {!data?.length ? (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
                >
                  No Data Found!
                </Typography>
              )}
            </Stack>
          ) : (
            <Stack sx={{ gap: 5 }}>
              {data?.map(
                (
                  { id, image, title, description, created_at, slug },
                  index
                ) => {
                  return (
                    <>
                      <Link to={`${PAGES.BLOGS.path}/${slug}`}>
                        <Grid
                          key={id}
                          container
                          spacing={{ xs: 3, sm: 3, md: 5 }}
                          // sx={{ bgcolor: "red" }}
                        >
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={3.5}>
                            <Box
                              component="img"
                              src={image || DefaultImage}
                              alt={title}
                              sx={{
                                width: "100%",
                                aspectRatio: "16/9",
                                objectFit: "cover",
                                borderRadius: 3,
                                // maxWidth: "500px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={8} xl={8.5}>
                            <Stack
                              sx={{
                                height: "100%",
                                width: "100%",
                                justifyContent: "space-evenly",
                                gap: { xs: 2, sm: 2, md: 1.5 },
                              }}
                            >
                              <Stack sx={{ gap: 0.5 }}>
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.grey[600],
                                    fontWeight: 600,
                                  }}
                                >
                                  {moment(created_at).format("MMM DD, YYYY")}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 600,
                                  }}
                                >
                                  {title}
                                </Typography>
                              </Stack>

                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: description,
                                }}
                                sx={{
                                  p: {
                                    color: theme.palette.common.black,
                                    ...lineClamp(6),
                                  },
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Link>

                      {index !== data?.length - 1 && <Divider />}
                    </>
                  );
                }
              )}
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
