import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import defaultImage from "assets/imgs/default/news_default.png";
import "./newsDetail.scss";
import { theme } from "utils/theme";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import { LANDING_THEME } from "utils/landingTheme";
import LoadingScreen from "components/LandingComponents/loading-screen";
import { useSelector } from "react-redux";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { getLandingNewsDetails } from "store/slices/landingSite/reducer";

export function NewsDetails() {
  const { id } = useParams();
  // const [news, setNews] = useState(null);
  // // const { setLoading } = useContext(LayoutContext);

  // const [loading, setLoading] = useState();

  // useEffect(() => {
  //   const fetchNewsDetails = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await getApi("/getNewsDetails", "POST", {
  //         news_id: id,
  //       });
  //       console.log(response);
  //       setNews(response.data);
  //     } catch (error) {
  //       console.error("Error fetching news posts:", error);
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchNewsDetails();
  // }, [id]);

  const [fetchData] = useDispatchWithAbort(getLandingNewsDetails);

  const {
    newsDetails: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({
      news_id: id,
    });
  }, [fetchData, id]);

  if (loading) return <LoadingScreen />;

  const { HEADER_HEIGHT } = LANDING_THEME;

  return (
    <>
      {!data?.id ? (
        <Stack
          sx={{
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
          >
            No Data Found!
          </Typography>
        </Stack>
      ) : (
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Stack sx={{ gap: { xs: 4, sm: 6 } }}>
            <Stack sx={{ gap: 2 }}>
              <Typography
                variant="h3"
                sx={{
                  background: theme.palette.common.black,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 600,
                  fontSize: 45,
                  textAlign: "center",
                }}
              >
                {data?.title || "-"}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.grey[500],
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Published on:{" "}
                <Box component="span" sx={{ color: "#007bff" }}>
                  {moment(data?.created_at).format("MMM D, YYYY") || "-"}
                </Box>
              </Typography>
            </Stack>

            <Divider />

            <Box
              component="img"
              src={data?.image || defaultImage}
              alt={data?.title}
              sx={{
                width: "100%",
                aspectRatio: "16/9",
                objectFit: "cover",
                borderRadius: 3,
                maxWidth: "900px",
                alignSelf: "center",
                // maxWidth: "500px",
              }}
            />

            <Divider />

            <Stack>
              <Box
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
                sx={{
                  p: {
                    color: theme.palette.common.black,
                    fontSize: 18,
                  },
                }}
              />
            </Stack>
          </Stack>
        </Container>
      )}
    </>
  );
}
