import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getApi } from "utils/customNetwork";

export const getLandingFeatures = createAsyncThunk(
    "landingSite/getLandingFeatures",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getFeatresList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingPricing = createAsyncThunk(
    "landingSite/getLandingPricing",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getPricingList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingBlogs = createAsyncThunk(
    "landingSite/getLandingBlogs",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getBlogsList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingBlogDetails = createAsyncThunk(
    "landingSite/getLandingBlogDetails",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getBlogDetails", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingNews = createAsyncThunk(
    "landingSite/getLandingNews",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getNewsList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingNewsDetails = createAsyncThunk(
    "landingSite/getLandingNewsDetails",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getNewsDetails", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingPrivacyPolicy = createAsyncThunk(
    "landingSite/getLandingPrivacyPolicy",
    async (_, { rejectWithValue }) => {
        try {
            return await getApi("/fetchPricvacyPolicy", "GET");
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingTermsOfService = createAsyncThunk(
    "landingSite/getLandingTermsOfService",
    async (_, { rejectWithValue }) => {
        try {
            return await getApi("/fetchTermsService", "GET");
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingFaqs = createAsyncThunk(
    "landingSite/getLandingFaqs",
    async (_, { rejectWithValue }) => {
        try {
            return await getApi("/getFaqList", "GET");
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const initialState = {
    features: {
        data: [],
        loading: false,
    },
    pricing: {
        data: [],
        loading: false,
    },
    blogs: {
        data: [],
        loading: false,
    },
    blogDetails: {
        data: {},
        loading: false,
    },
    news: {
        data: [],
        loading: false,
    },
    newsDetails: {
        data: {},
        loading: false,
    },
    privacyPolicy: {
        data: {},
        loading: false,
    },
    termsOfService: {
        data: {},
        loading: false,
    },
    faqs: {
        data: [],
        loading: false,
    },
};

export const landingSiteSlice = createSlice({
    name: "landingSite",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingFeatures.pending, (state) => {
                state.features.loading = true;
            })
            .addCase(getLandingFeatures.fulfilled, (state, action) => {
                state.features.loading = false;
                const {
                    payload
                } = action;
                state.features.data = payload?.records || []
            })
            .addCase(getLandingFeatures.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.features.loading = false;
            });
        builder
            .addCase(getLandingPricing.pending, (state) => {
                state.pricing.loading = true;
            })
            .addCase(getLandingPricing.fulfilled, (state, action) => {
                state.pricing.loading = false;
                const {
                    payload
                } = action;
                state.pricing.data = payload?.records || []
            })
            .addCase(getLandingPricing.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.pricing.loading = false;
            });
        builder
            .addCase(getLandingBlogs.pending, (state) => {
                state.blogs.loading = true;
            })
            .addCase(getLandingBlogs.fulfilled, (state, action) => {
                state.blogs.loading = false;
                const {
                    payload
                } = action;
                state.blogs.data = payload?.records || []
            })
            .addCase(getLandingBlogs.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.blogs.loading = false;
            });
        builder
            .addCase(getLandingBlogDetails.pending, (state) => {
                state.blogDetails.loading = true;
            })
            .addCase(getLandingBlogDetails.fulfilled, (state, action) => {
                state.blogDetails.loading = false;
                const {
                    payload
                } = action;
                state.blogDetails.data = payload || {}
            })
            .addCase(getLandingBlogDetails.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.blogDetails.loading = false;
            });
        builder
            .addCase(getLandingNews.pending, (state) => {
                state.news.loading = true;
            })
            .addCase(getLandingNews.fulfilled, (state, action) => {
                state.news.loading = false;
                const {
                    payload
                } = action;
                state.news.data = payload?.records || []
            })
            .addCase(getLandingNews.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.news.loading = false;
            });
        builder
            .addCase(getLandingNewsDetails.pending, (state) => {
                state.newsDetails.loading = true;
            })
            .addCase(getLandingNewsDetails.fulfilled, (state, action) => {
                state.newsDetails.loading = false;
                const {
                    payload
                } = action;
                state.newsDetails.data = payload?.data || {}
            })
            .addCase(getLandingNewsDetails.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.newsDetails.loading = false;
            });
        builder
            .addCase(getLandingPrivacyPolicy.pending, (state) => {
                state.privacyPolicy.loading = true;
            })
            .addCase(getLandingPrivacyPolicy.fulfilled, (state, action) => {
                state.privacyPolicy.loading = false;
                const {
                    payload
                } = action;
                state.privacyPolicy.data = payload?.data || {}
            })
            .addCase(getLandingPrivacyPolicy.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.privacyPolicy.loading = false;
            });
        builder
            .addCase(getLandingTermsOfService.pending, (state) => {
                state.termsOfService.loading = true;
            })
            .addCase(getLandingTermsOfService.fulfilled, (state, action) => {
                state.termsOfService.loading = false;
                const {
                    payload
                } = action;
                state.termsOfService.data = payload?.data || {}
            })
            .addCase(getLandingTermsOfService.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.termsOfService.loading = false;
            });
        builder
            .addCase(getLandingFaqs.pending, (state) => {
                state.faqs.loading = true;
            })
            .addCase(getLandingFaqs.fulfilled, (state, action) => {
                state.faqs.loading = false;
                const {
                    payload
                } = action;
                state.faqs.data = payload?.data || {}
            })
            .addCase(getLandingFaqs.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.faqs.loading = false;
            });
    },
});

// export const { } = landingSiteSlice.actions;

export default landingSiteSlice.reducer;
