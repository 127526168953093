import IconBreadcrumb from "assets/imgs/icon_breadcrumb.svg";
import { ReactComponent as Logout } from "assets/imgs/menu/icon_out.svg";
import "./styles.scss";
import { getApi } from "utils/customNetwork";
import { useContext, useState } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useNavigate } from "react-router-dom";
import Notifications from "./Notifications";

export function AdminHeader({
  page = "dashboard",
  caption = "Dashboard",
  message = "Welcome to Creativio AI",
}) {
  const navigate = useNavigate();
  const {
    mobileSide,
    adminUser,
    checkToken,
    setLoading,
    showNoti,
    setAdminUser
  } = useContext(LayoutContext);
  const [showMenu, setShowMenu] = useState(false);

  const showUserName = (user) => {
    if (user?.user?.first_name !== "") {
      return user?.user?.first_name;
    } else {
      return user?.user?.email.substring(0, 2);
    }
  };
  const shortName = (user) => {
    if (user?.user?.first_name !== "") {
      return user?.user?.first_name.substring(0, 2).toUpperCase();
    } else {
      return user?.user?.email.substring(0, 2).toUpperCase();
    }
  };
  const goto = (newPage) => {
    if (newPage === page) return;
    switch (newPage) {
      case "logout":
        setAdminUser(null);
        signOut();
        navigate("/admin/login");
        break;
      default:
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      const data = await getApi("/logout", "POST", {});
      checkToken();
      showNoti("success", data.message);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  return (
    <div className={`header ${mobileSide ? "mobile" : ""}`}>
      <div className="content">
        <div className="left-content flex">
          <span>{caption}</span>
          <img src={IconBreadcrumb} alt="breadcrumb" />
          <span>{message},</span>
          <span className="text-graditent text-capitalize">
            &nbsp;{showUserName(adminUser)}
          </span>
          !
        </div>
        <div className="right-content">
          <span className="button-right-push flex">
            <Notifications />
          </span>
          {/* <img src={Avatar} className="user-image cursor-pointer" onClick={()=>setShowMenu(!showMenu)} /> */}
          <div
            className="user-image cursor-pointer"
            onClick={() => setShowMenu(!showMenu)}
          >
            {shortName(adminUser)}
          </div>
          {showMenu && (
            <div className="menu-area">
              <div className="cover-area" onClick={() => setShowMenu(false)} />
              <div className="dropdown">
                {/* <div className={`dropdown-menu ${page === 'profile' ? 'active' : ''}`} onClick={()=>goto('profile')}>
                                <div className="menu">
                                    <div className='img-wrap'><Profile /></div>
                                    Profile
                                </div>
                            </div>
                            <div className={`dropdown-menu ${page === 'knowledge' ? 'active' : ''}`} onClick={()=>goto('knowledge')}>
                                <div className="menu">
                                    <div className='img-wrap'><Knowledge /></div>
                                    Knowledge Base
                                </div>
                            </div>
                            <div className={`dropdown-menu ${page === 'settings' ? 'active' : ''}`} onClick={()=>goto('settings')}>
                                <div className="menu">
                                    <div className='img-wrap'><Setting /></div>
                                    Settings
                                </div>
                            </div>
                            <div className={`dropdown-menu ${page === 'brand' ? 'active' : ''}`} onClick={()=>goto('brand')}>
                                <div className="menu">
                                    <div className='img-wrap'><Brand /></div>
                                    Brand Settings
                                </div>
                            </div> */}
                <div
                  className={`dropdown-menu ${page === "Logout" ? "active" : ""
                    }`}
                  onClick={() => goto("logout")}
                >
                  <div className="menu">
                    <div className="img-wrap">
                      <Logout />
                    </div>
                    Logout
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
