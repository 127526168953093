// Pricing.js
import React, { useEffect } from "react";
import "./styles.scss";
import { Container, Stack, Typography, Grid, Box, Button } from "@mui/material";
import { theme } from "utils/theme";
import { IMAGES } from "assets";
import LoadingScreen from "components/LandingComponents/loading-screen";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { getLandingPricing } from "store/slices/landingSite/reducer";
import { useSelector } from "react-redux";

// const pricingPlans = [
//   {
//     licenseType: "Basic",
//     source: "Cloud",
//     price: "$10/month",
//     description: "Perfect for individuals looking to get started."
//   },
//   {
//     licenseType: "Pro",
//     source: "Cloud",
//     price: "$20/month",
//     description: "Ideal for small businesses wanting to enhance productivity."
//   },
//   {
//     licenseType: "Enterprise",
//     source: "On-premise",
//     price: "$50/month",
//     description: "Best for large organizations with advanced needs."
//   },
// ];

export function Pricing() {
  const [fetchData] = useDispatchWithAbort(getLandingPricing);

  const {
    pricing: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({ page: 1, page_size: 20 });
  }, [fetchData]);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page
  // //   const { setLoading } = useContext(LayoutContext);

  // const [loading, setLoading] = useState(true);

  // const [pricingPlans, setpricingPlans] = useState([]);
  // const itemsPerPage = 10; // Set how many items you want to show per page
  // // const pricingListRef = useRef(null); // Reference for the pricing list container

  // const fetchPlans = useCallback(async () => {
  //   if (isLast) return; // Prevent fetching if it's the last page

  //   setLoading(true);
  //   try {
  //     const data = await getApi("/getPricingList", "POST", {
  //       page: currentPage,
  //       page_size: itemsPerPage,
  //     });
  //     console.log(data);
  //     if (data.records !== undefined && data.records.length > 0) {
  //       setpricingPlans((prevItems) => [...prevItems, ...data.records]);
  //       setIsLast(data.last); // If `data.last` is true, no more posts to load
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching pricing plans:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage, isLast]);

  // useEffect(() => {
  //   setpricingPlans([]);
  //   setCurrentPage(1);
  //   setIsLast(false);
  // }, []);

  // useEffect(() => {
  //   fetchPlans();
  // }, [currentPage, fetchPlans]);

  // const handleScroll = throttle((e) => {
  //   if (isLast) return; // If it's the last page, no need to load more

  //   const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
  //   const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

  //   if (scrollPercentage > 95) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll); // Clean up event listener
  //   };
  // }, [handleScroll]);

  if (loading && !data?.length) return <LoadingScreen />;

  return (
    <Stack
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          background: `url(${IMAGES.HeroBackground})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          transform: "rotate(180deg);",
          transformOrigin: "center",
        },
      }}
    >
      <Container maxWidth="xl" sx={{ py: 8, zIndex: 1, minHeight: "100vh" }}>
        <Stack sx={{ gap: 8 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              CreativioAI Pricing Plans:
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Here's A Recap Of What You Are Getting Access To Today
            </Typography>
          </Stack>

          {!data?.length ? (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
              >
                No Data Found!
              </Typography>
            </Stack>
          ) : (
            <Stack>
              <Grid
                container
                spacing={{ xs: 8 }}
                sx={{ justifyContent: "center" }}
              >
                {data?.map(({ id, license_type, price, source }) => {
                  return (
                    <Grid item key={id} xs={12} sm={6}>
                      <Stack
                        sx={{
                          border: `1px solid ${theme.palette.primary.main}`,
                          borderRadius: 8,
                          overflow: "hidden",
                          boxShadow:
                            "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                        }}
                      >
                        <Stack
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            background: `url(${IMAGES.BannerBgLarge})`,
                            backgroundPosition: "bottom",
                            backgroundSize: "cover",
                            py: 8,
                            px: 3,
                          }}
                        >
                          <Stack sx={{ alignItems: "center" }}>
                            <Box
                              component="img"
                              src={IMAGES.Logo3d}
                              alt={`Logo3d-${id}`}
                              sx={{ width: "100%", maxWidth: "200px" }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                color: theme.palette.common.white,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {`Creativio AI - ${license_type}`}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack sx={{ p: 5, alignItems: "center", gap: 6 }}>
                          <Stack sx={{ gap: 1 }}>
                            <Typography
                              variant="h4"
                              sx={{
                                background: theme.palette.primaryGradient.main,
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >{`Only $${price}!`}</Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                color: theme.palette.primary.extraDark,
                                textAlign: "center",
                              }}
                            >
                              Commitment Free - Cancel Anytime!
                            </Typography>
                          </Stack>
                          <Button
                            variant="contained"
                            size="large"
                            sx={{
                              borderRadius: "36.5px",
                              fontWeight: 600,
                              background: theme.palette.primaryGradient.main,
                              py: 1.5,
                              px: 5,
                              fontSize: 16,
                            }}
                          >
                            Buy Now
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
