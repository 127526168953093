import { Box, Button, Container, Stack, Typography } from "@mui/material";
import {
  ICONS,
  IMAGES,
  INSTANT_ACCESS_CARD_BRAND_IMAGES,
  INSTANT_ACCESS_IMAGES,
} from "assets";
import React from "react";
import { theme } from "utils/theme";

const InstantAccess = ({ type }) => {
  const INSTANT_ACCESS_CARD_BRANDS = [
    INSTANT_ACCESS_CARD_BRAND_IMAGES.Visa,
    INSTANT_ACCESS_CARD_BRAND_IMAGES.Paypal,
    INSTANT_ACCESS_CARD_BRAND_IMAGES.Mastercard,
    INSTANT_ACCESS_CARD_BRAND_IMAGES.AmericanExpress,
    INSTANT_ACCESS_CARD_BRAND_IMAGES.Discover,
  ];

  const isCard = type === "card";

  return (
    <Container
      maxWidth={isCard ? "md" : "lg"}
      sx={isCard ? { px: { xs: 0 } } : { py: { xs: 4, sm: 6, md: 8 } }}
    >
      <Stack sx={{ gap: 5 }}>
        {!isCard && (
          <Stack sx={{ gap: 3, justifyContent: "center" }}>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Grab Instant Access to Creativio AI Today…
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.common.black, textAlign: "center" }}
            >
              No monthly fees - One Time Payment!
            </Typography>
          </Stack>
        )}
        <Box
          sx={{
            backgroundImage: `url(${IMAGES.BannerBg})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            p: { xs: 4, sm: 6, md: 8 },
            borderRadius: 8,
          }}
        >
          <Stack sx={{ alignItems: "center", gap: 5 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.secondaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Today: Just $47 (52% Discount)
            </Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                textTransform: "none",
                borderRadius: "36.5px",
                fontWeight: 600,
                background: theme.palette.primaryGradient.main,
                py: 1.5,
                px: 5,
                fontSize: 16,
              }}
            >
              Get Creativio AI Right Now
            </Button>
            <Stack
              sx={{
                flexDirection: "row",
                gap: 2.5,
                alignItems: "center",
                justifyContent: "center",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <Stack
                sx={{ flexDirection: "row", gap: 1.5, alignItems: "center" }}
              >
                {INSTANT_ACCESS_CARD_BRANDS.map((image, index) => {
                  return (
                    <Box
                      key={index}
                      component="img"
                      src={image}
                      alt={`Brand Card ${index}`}
                    />
                  );
                })}
              </Stack>

              <Stack
                sx={{ flexDirection: "row", gap: 1, alignItems: "center" }}
              >
                <ICONS.VerifiedUser
                  sx={{ color: theme.palette.primary.main, fontSize: 35 }}
                />
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.common.white, lineHeight: 1.45 }}
                >
                  30 Days Money <br /> Back Guarantee
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{ flexDirection: "row", gap: 1.5, alignItems: "center" }}
            >
              <Box
                component="img"
                src={INSTANT_ACCESS_IMAGES.CompatibleMac}
                alt="CompatibleMac"
              />
              <Box
                component="img"
                src={INSTANT_ACCESS_IMAGES.CompatibleWindows}
                alt="CompatibleWindows"
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default InstantAccess;
