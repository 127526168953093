// PrivacyPolicy.js
import { React, useEffect } from "react";
import "./styles.scss";
import { Box, Container, Stack, Typography } from "@mui/material";
import { theme } from "utils/theme";
import { IMAGES } from "assets";
import LoadingScreen from "components/LandingComponents/loading-screen";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { getLandingPrivacyPolicy } from "store/slices/landingSite/reducer";
import { useSelector } from "react-redux";

export function PrivacyPolicy() {
  const [fetchData] = useDispatchWithAbort(getLandingPrivacyPolicy);
  const {
    privacyPolicy: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading && !data?.description) return <LoadingScreen />;

  return (
    <Stack>
      <Stack
        sx={{
          background: `url(${IMAGES.BannerBgLarge})`,
          minHeight: { xs: "160px", sm: "250px" },
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontWeight: 600,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Privacy Policy
          </Typography>
        </Container>
      </Stack>

      {!data?.description ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
          >
            No Data Found!
          </Typography>
        </Stack>
      ) : (
        <Container maxWidth="xl" sx={{ py: 8 }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
            sx={{
              p: {
                color: theme.palette.common.black,
                fontSize: 18,
              },
            }}
          />
        </Container>
      )}
    </Stack>
  );
}
