import { createTheme } from "@mui/material";
import { palette } from "./palette";
import { typography } from "./typography";
import { componentsStyle } from "./components-style";

const defaultTheme = createTheme({ palette });

export const theme = createTheme({
  ...defaultTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xl3: 2400,
      xl4: 3000,
      xl5: 3600,
    },
  },
  typography: typography(defaultTheme),
  components: componentsStyle(defaultTheme),
});

export const LANDING_THEME = { HEADER_HEIGHT: 75 };
