import React, { useState, useContext } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { theme } from "utils/theme";
import { Link } from "react-router-dom";
import { ICONS } from "assets";
import Button from "components/LandingComponents/button";
import Input from "components/LandingComponents/input";
import { useForm } from "react-hook-form";
import { contactUsSchema } from "utils/validations";
import { zodResolver } from "@hookform/resolvers/zod";
import { LANDING_THEME } from "utils/landingTheme";

export function ContactUs() {
  const { showNoti } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(contactUsSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await getApi("/submitContactUs", "POST", data);
      setLoading(false);
      showNoti("success", "Your message sent successfully!");
      reset();
    } catch (error) {
      setLoading(false);
      showNoti("error", "Oops! Something went wrong. Try later.");
    }
  };

  const SOCIAL_LINKS = [
    { id: 1, Icon: ICONS.Instagram, to: "https://www.instagram.com" },
    { id: 2, Icon: ICONS.Facebook, to: "https://www.facebook.com" },
    { id: 3, Icon: ICONS.X, to: "https://x.com" },
    { id: 4, Icon: ICONS.LinkedIn, to: "https://www.linkedin.com" },
    { id: 5, Icon: ICONS.YouTube, to: "https://www.youtube.com" },
  ];

  const { HEADER_HEIGHT } = LANDING_THEME;

  return (
    <Stack
      sx={{
        background:
          "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Stack sx={{ gap: 10 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              Get in Touch with Us
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              We’d love to hear from you! Fill out the form below, and we’ll get
              back to you as soon as possible.
            </Typography>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                sx={{
                  height: "calc(100% - 64px)",
                  // width: "100%",
                  bgcolor: theme.palette.primary.darkest,
                  borderRadius: 4,
                  px: 4,
                  py: 4,
                  gap: 4,
                  justifyContent: "center",
                }}
              >
                <Stack sx={{ gap: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "white", fontWeight: 600 }}
                  >
                    Get in Touch
                  </Typography>
                  <Stack sx={{ gap: 1 }}>
                    <Typography variant="body1" sx={{}}>
                      <Box
                        component="span"
                        sx={{
                          color: theme.palette.primary.light,
                          fontWeight: 600,
                        }}
                      >
                        Mobile :{" "}
                      </Box>
                      +123 456 7890
                    </Typography>
                    <Typography variant="body1" sx={{}}>
                      <Box
                        component="span"
                        sx={{
                          color: theme.palette.primary.light,
                          fontWeight: 600,
                        }}
                      >
                        Email :{" "}
                      </Box>
                      support@creativioai.com
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ gap: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "white", fontWeight: 600 }}
                  >
                    Follow Us on Social Media
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {SOCIAL_LINKS.map(({ id, to, Icon }) => {
                      return (
                        <Link
                          key={id}
                          {...{ to }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon
                            sx={{
                              fontSize: 30,
                              color: theme.palette.primary.main,
                              ":hover": {
                                color: "white",
                              },
                            }}
                          />
                        </Link>
                      );
                    })}
                  </Stack>
                </Stack>
                <Typography variant="subtitle1" sx={{ color: "white" }}>
                  We’re here to make your experience smooth and enjoyable. Don’t
                  hesitate to reach out!
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  height: "calc(100% - 64px)",
                  bgcolor: "white",
                  borderRadius: 4,
                  px: 4,
                  py: 4,
                  gap: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: 600,
                  }}
                >
                  Send Message To Us
                </Typography>
                <Stack sx={{ gap: 3 }}>
                  <Input name="name" label="Name" {...{ control }} />
                  <Input name="email" label="Email Address" {...{ control }} />
                  <Input name="subject" label="Inquiry Type" {...{ control }} />
                  <Input
                    name="message"
                    label="Message"
                    multiline={true}
                    minRows={3}
                    maxRows={5}
                    {...{ control }}
                  />
                  <Button
                    {...{ loading }}
                    type="submit"
                    sx={{ alignSelf: "start" }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Stack>
  );
}
