import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ICONS, IMAGES } from "assets";
import useToggleState from "hooks/useToggleState";
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { PAGES } from "utils/urls";

const Header = ({ HEADER_HEIGHT }) => {
  const theme = useTheme();

  const { pathname } = useLocation();
  const { state, toggle } = useToggleState();

  const MENU_ITEMS = [PAGES.FEATURES, PAGES.PRICING, PAGES.BLOGS, PAGES.NEWS];

  return (
    <Fragment>
      <AppBar
        sx={{
          width: "100%",
          zIndex: theme.zIndex.drawer + 1,
          bgcolor: "#1F0C5A",
          height: `${HEADER_HEIGHT}px`,
        }}
      >
        <Container maxWidth="xl" sx={{ height: "100%" }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Link to={PAGES.HOME.path} style={{ textDecoration: "none" }}>
              <Box component="img" src={IMAGES.LandingLogo} alt="logo" />
            </Link>

            <Button
              variant="outlined"
              size="small"
              sx={{
                display: { xs: "flex", sm: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
                p: "5px",
                minWidth: "auto",
              }}
              onClick={toggle}
            >
              <ICONS.MenuBars
                sx={{
                  color: theme.palette.common.white,
                  fontSize: 28,
                }}
              />
            </Button>

            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              {MENU_ITEMS.map(({ id, name, path }) => {
                return (
                  <Link key={id} to={path} style={{ textDecoration: "none" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.common.white,
                        position: "relative",
                        "&::before": {
                          content: '""',
                          width: path === pathname ? "100%" : "0%",
                          height: "2px",
                          backgroundColor: "#7371FC",
                          display: "block",
                          transition: "width 0.5s",
                          position: "absolute",
                          left: 0,
                          bottom: -2,
                          borderRadius: "2px",
                        },
                        "&:hover::before": {
                          width: "100%",
                        },
                      }}
                    >
                      {name}
                    </Typography>
                  </Link>
                );
              })}
              <Link to={PAGES.LOGIN.path} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "36.5px",
                    background: theme.palette.primaryGradient.main,
                  }}
                >
                  Login
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Container>
      </AppBar>

      {/* Mobile menu */}
      <Drawer anchor="top" open={state}>
        <Stack
          sx={{
            marginTop: `${HEADER_HEIGHT}px`,
            minHeight: `calc(100vh - (${HEADER_HEIGHT}px + 112px))`,
            bgcolor: theme.palette.common.white,
            p: 7,
            gap: 4,
            alignItems: "center",
          }}
        >
          {MENU_ITEMS.map(({ id, name, path }) => {
            return (
              <Link
                key={id}
                to={path}
                style={{ textDecoration: "none" }}
                onClick={toggle}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#36379B",
                    position: "relative",
                    "&::before": {
                      content: '""',
                      width: path === pathname ? "100%" : "0%",
                      height: "3px",
                      backgroundColor: "primary.main",
                      display: "block",
                      transition: "width 0.5s",
                      position: "absolute",
                      left: 0,
                      bottom: -2,
                      borderRadius: "3px",
                    },
                    "&:hover::before": {
                      width: "100%",
                    },
                    fontWeight: 600,
                  }}
                >
                  {name}
                </Typography>
              </Link>
            );
          })}
          <Divider
            sx={{
              bgcolor: "primary.extraDark",
              width: "100%",
              height: "1px",
              borderRadius: "1px",
            }}
          />
          <Link
            to={PAGES.LOGIN.path}
            style={{ textDecoration: "none" }}
            onClick={toggle}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "36.5px",
                background: theme.palette.primaryGradient.main,
              }}
            >
              Login
            </Button>
          </Link>
        </Stack>
      </Drawer>
    </Fragment>
  );
};

export default Header;
