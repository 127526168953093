import { DashboardLayout } from "components/layouts/DashboardLayout";
import { Slide } from "react-awesome-reveal";
import "./styles.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as IcoDelete } from "assets/imgs/action/icon_trush.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { download } from "utils/imageUtil";
import CImage from "components/atoms/CImage";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { convertHeicImage } from "pages/Edit/utils";
import { LICENSE } from "utils/constants";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import { Grid } from "@mui/material";

export function Assets() {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { showNoti, setLoading, checkToken, user } = useContext(LayoutContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [assetsImgs, setAssetsImgs] = useState(null);
  const [newImage, setNewImage] = useState(null); // new image - file
  const [orgImageWidth, setOrgImageWidth] = useState(1024); // new image - file
  const [orgImageHeight, setOrgImageHeight] = useState(1024); // new image - file
  const [selectedImages, setSelectedImages] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onReadMore = () => {
    let curPage = currentPage;
    curPage++;
    getAssets(curPage);
  };

  const handleRemove = async (asset_id, index) => {
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/removeAsset/" + asset_id, "POST", {});
      showNoti("success", "Asset Deleted Successfully!");
      // const updatedImages = [...assetsImgs];
      // updatedImages.splice(index, 1);
      // setAssetsImgs(updatedImages);
      // if (updatedImages.length < 10) {
      //     getAssets(1);
      // }
      if (selectedImages.includes(asset_id)) {
        setSelectedImages(selectedImages.filter((id) => id !== asset_id));
      }
      getAssets(1);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const getAssets = async (curPage = 1) => {
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/getAssets", "POST", {
        user_id: user.user.id,
        page_size: 10,
        page: curPage,
      });
      setCurrentPage(curPage);
      if (curPage > 1) {
        setAssetsImgs([...assetsImgs, ...data.assets]);
      } else {
        setAssetsImgs(data.assets);
      }
      setIsLast(data.last);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const onUploadImage = async () => {
    const formData = new FormData();
    formData.append("image", newImage);
    formData.append("width", orgImageWidth);
    formData.append("height", orgImageHeight);
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/storeAsset", "POST", formData);
      showNoti("success", "Asset Uploaded Successfully!");
      if (assetsImgs.length == 0) {
        setAssetsImgs(data.asset);
      } else {
        setAssetsImgs([...data.asset, ...assetsImgs]);
      }
    } catch (err) {
      setNewImage(null);
      showNoti("error", err);
    }
    setOrgImageHeight(1024);
    setOrgImageWidth(1024);
    setLoading(false);
  };

  const onUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    if (file.type === "" || file.type === "image/heic") {
      const blob = await convertHeicImage(file);
      reader.onload = (event) => {
        const imageElement = new Image();
        imageElement.src = event.target.result;
        imageElement.onload = function () {
          setOrgImageWidth(imageElement.width);
          setOrgImageHeight(imageElement.height);
        };
      };
      // parseReader(reader);
      reader.readAsDataURL(blob);
      setNewImage(blob);
      return;
    }

    // parseReader(reader);
    reader.onload = (event) => {
      const imageElement = new Image();
      imageElement.src = event.target.result;
      imageElement.onload = function () {
        setOrgImageWidth(imageElement.width);
        setOrgImageHeight(imageElement.height);
      };
    };
    reader.readAsDataURL(file);
    setNewImage(file);
  };

  const parseReader = (reader) => {
    reader.onload = (event) => {
      const imageElement = new Image();
      imageElement.src = event.target.result;
      imageElement.onload = function () {
        setOrgImageWidth(imageElement.width);
        setOrgImageHeight(imageElement.height);
      };
    };
  };

  useEffect(() => {
    if (newImage != null) {
      onUploadImage();
    }
  }, [newImage]);

  const onUploadNew = () => {
    navigate("/create/photoshoot");
  };

  const gotoPhotoshoot = (data) => {
    const param = {
      image: data.image,
      mask: data.mask,
      width: data.width,
      height: data.height,
    };
    const x = btoa(JSON.stringify(param)); // image data to base64
    navigate("/create/photoshoot?edit-image=" + x);
  };

  const toggleSelect = (imageId) => {
    if (selectedImages.includes(imageId)) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedImages.length === assetsImgs.length) {
      setSelectedImages([]);
    } else {
      const allImageIds = assetsImgs.map((item) => item.id);
      setSelectedImages(allImageIds);
    }
  };

  const handleDeleteSelected = () => {
    setShowConfirmation(true);
  };

  const closeDeleteConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/removeAssets", "POST", {
        user_id: user.user.id,
        selected_assets: selectedImages,
      });
      showNoti("success", "Assets Deleted Successfully!");
      setSelectedImages([]);
      getAssets(1);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    setShowConfirmation(false);
  };

  useEffect(() => {
    getAssets();
    setNewImage(null);
    setOrgImageHeight(1024);
    setOrgImageWidth(1024);
  }, []);

  return (
    <DashboardLayout
      page="assets"
      caption="Your Image Assets"
      message="Welcome to Creativio AI"
      license={[[LICENSE.BUSINESS], [LICENSE.STARTER]]}
    >
      <div id="assets" className="body-container">
        <div className="section2">
          <Slide triggerOnce={true}>
            <h1>
              <span>Your</span> Image Assets
            </h1>
          </Slide>
          <div className="new-btn">
            {selectedImages.length > 0 && (
              <CButton
                addClass={`error ${
                  selectedImages.length === 0 ? "disabled" : ""
                }`}
                onClick={handleDeleteSelected}
                disabled={selectedImages.length === 0}
              >
                Delete Selected
              </CButton>
            )}
            <CButton
              addClass="gradient"
              onClick={toggleSelectAll}
              tooltipId="tip-select-all"
              tooltipContent="Select all visible assets for bulk deletion"
              tooltipVariant="dark"
            >
              {assetsImgs && selectedImages.length === assetsImgs.length
                ? "Deselect All"
                : "Select All"}
            </CButton>
            <CButton addClass="gradient" active={true} onClick={onUploadClick}>
              Upload Image
            </CButton>
            <CButton addClass="gradient" active={true} onClick={onUploadNew}>
              Upload New
            </CButton>
          </div>
          <div className="trend-area mt-25">
            <input
              type="file"
              accept=".png"
              onInput={handleImageUpload}
              onChange={(e) => (e.target.value = null)}
              style={{ display: "none" }}
              ref={fileInputRef}
            />

            <Grid container spacing={3} columns={21}>
              {assetsImgs &&
                assetsImgs.map((k, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={21}
                      sm={21}
                      md={10.5}
                      lg={7}
                      xl={5.25}
                      xxl={4.2}
                      xl3={3}
                      xl4={2.625}
                    >
                      <div className={`result-assets-card`}>
                        {/* <img src={k.image} alt={k.seed} onClick={() => selectImage(k)} /> */}
                        <CImage
                          src={k.image}
                          alt={k.seed}
                          //   width={310}
                          //   height={314}
                        />
                        <div className="card-action-bar">
                          <div className="first-part checkbox-wrapper">
                            <input
                              type="checkbox"
                              checked={selectedImages.includes(k.id)}
                              onChange={() => toggleSelect(k.id)}
                              data-tooltip-id={`tip-select-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content={
                                selectedImages.includes(k.id)
                                  ? "Deselect"
                                  : "Select"
                              }
                            />
                            <Tooltip id={`tip-select-${index}`} />
                          </div>
                          <div className="last-part">
                            <DownloadImg
                              data-tooltip-id={`tip-download-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content="Download"
                              className="action-btn"
                              onClick={() => download(k.image, "download.png")}
                            />
                            <Tooltip id={`tip-download-${index}`} />
                            <IcoDelete
                              data-tooltip-id={`tip-delete-${index}`}
                              data-tooltip-variant="light"
                              data-tooltip-content="Delete"
                              className="action-btn"
                              onClick={() => handleRemove(k.id, index)}
                            />
                            <Tooltip id={`tip-delete-${index}`} />
                          </div>
                        </div>
                        <div className="card-action-bottom-bar">
                          <CButton
                            addClass="primary active"
                            onClick={() => gotoPhotoshoot(k)}
                          >
                            Generate
                          </CButton>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
          {isLast && assetsImgs.length > 0 && (
            <div className="text-left mt-20 mb-20">
              <CButton addClass="gradient" active={true} onClick={onReadMore}>
                Load More
              </CButton>
            </div>
          )}
        </div>
        {showConfirmation && (
          <ConfirmationModal
            isOpen={showConfirmation}
            onClose={closeDeleteConfirmationModal}
            onConfirm={() => confirmDeletion()}
            itemModule="Assets"
            itemName="selected Assets"
          />
        )}
      </div>
    </DashboardLayout>
  );
}
