import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import { CTextarea } from "components/atoms/CTextarea";
import "./styles.scss";
export function AdminHomePage() {
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const [formData, setFormData] = useState({
        id: "",
        bannerSection: {
            bannerMainHeading: "",
            bannerSubHeading: "",
            bannerPeopleHeading: "",
        },
        informationSection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
        traditionalPhotographySection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
        creativioShotsSection: [
            { id: 1, value: "" },
            { id: 2, value: "" },
        ],
    });

    const fetchHomeData = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/getHomeData");
            if (response?.data) {
                const { banner_section, information_section, traditional_photography, creativio_shots, id } = response.data;

                setFormData((prev) => ({
                    ...prev,
                    id: id || "",
                    bannerSection: banner_section || {},
                    informationSection: information_section ? Object.values(information_section).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                    traditionalPhotographySection: traditional_photography ? Object.values(traditional_photography).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                    creativioShotsSection: creativio_shots ? Object.values(creativio_shots).map((info, index) => ({
                        id: index + 1,
                        value: info,
                    })) : [{ id: 1, value: "" }, { id: 2, value: "" }],
                }));
            }
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split(".");

        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();

        formDataToSubmit.append("banner_section[bannerMainHeading]", formData.bannerSection.bannerMainHeading);
        formDataToSubmit.append("banner_section[bannerSubHeading]", formData.bannerSection.bannerSubHeading);
        formDataToSubmit.append("banner_section[bannerPeopleHeading]", formData.bannerSection.bannerPeopleHeading);

        formData.informationSection.forEach((field, index) => {
            formDataToSubmit.append(`information_section[${index + 1}]`, field.value);
        });

        formData.traditionalPhotographySection.forEach((field, index) => {
            formDataToSubmit.append(`traditional_photography[${index + 1}]`, field.value);
        });

        formData.creativioShotsSection.forEach((field, index) => {
            formDataToSubmit.append(`creativio_shots[${index + 1}]`, field.value);
        });

        let apiUrl = "/admin/saveHomeData";

        try {
            setLoading(true);
            checkToken();
            const response = await getApi(apiUrl, "POST", formDataToSubmit);
            if (formData.id) {
                showNoti("success", "Home Page Updated Successfully!");
            } else {
                showNoti("success", "Home Page Added Successfully!");
            }
            fetchHomeData();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const handleInformationFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedInformationSection = prev.informationSection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, informationSection: updatedInformationSection };
        });
    };

    const handleTraditionalFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedTraditionalSection = prev.traditionalPhotographySection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, traditionalPhotographySection: updatedTraditionalSection };
        });
    };

    const handleCreativioFieldChange = (e, index) => {
        const { value } = e.target;
        setFormData((prev) => {
            const updatedCreativioSection = prev.creativioShotsSection.map((field, idx) =>
                idx === index ? { ...field, value } : field
            );
            return { ...prev, creativioShotsSection: updatedCreativioSection };
        });
    };

    const handleAddField = (section) => {
        const newId = formData[section].length + 1;
        if (newId <= 10) {
            setFormData((prev) => ({
                ...prev,
                [section]: [...prev[section], { id: newId, value: "" }],
            }));
        } else {
            showNoti("warning", "Maximum of 10 fields are allowed.");
        }
    };

    const handleRemoveField = (index, section) => {
        setFormData((prev) => {
            if (prev[section].length > 2) {
                const updatedSection = prev[section].filter((_, idx) => idx !== index);
                // Re-index the remaining fields
                const reIndexedSection = updatedSection.map((field, idx) => ({ ...field, id: idx + 1 }));
                return { ...prev, [section]: reIndexedSection };
            } else {
                showNoti("warning", "At least 2 fields are required.");
                return prev;
            }
        });
    };

    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-home-page body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>Home Page</span>
                            </h1>
                        </Slide>
                    </div>

                    <div className="mail-settings settings">
                        <form onSubmit={handleSubmit}>
                            <div className="section-container">
                                <h3 className="section-title">Banner Section</h3>
                                <div className="main-body-container">
                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_main_heading">Banner Main Heading:</label>
                                        <CTextarea
                                            id="banner_main_heading"
                                            name="bannerSection.bannerMainHeading"
                                            addClass="banner_main_heading"
                                            placeholder="Banner Main Heading"
                                            value={formData.bannerSection.bannerMainHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>

                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_sub_heading">Banner Sub Heading:</label>
                                        <CTextarea
                                            id="banner_sub_heading"
                                            name="bannerSection.bannerSubHeading"
                                            addClass="banner_sub_heading"
                                            placeholder="Banner Sub Heading:"
                                            value={formData.bannerSection.bannerSubHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>

                                    <div className="form-group mt-20">
                                        <label htmlFor="banner_people_heading">Banner People Heading:</label>
                                        <CTextarea
                                            id="banner_people_heading"
                                            name="bannerSection.bannerPeopleHeading"
                                            addClass="banner_people_heading"
                                            placeholder="Banner People Heading"
                                            value={formData.bannerSection.bannerPeopleHeading}
                                            onChange={handleChange}
                                            rows="2"
                                            required
                                        ></CTextarea>
                                    </div>
                                </div>
                            </div>

                            {/* Information Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Information Section</h3>
                                    <CButton onClick={() => handleAddField("informationSection")} className="add-field-button">
                                        Add Information Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.informationSection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`information_${field.id}`}>Information {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`information_${field.id}`}
                                                    name={`informationSection[${field.id}]`}
                                                    placeholder={`Information ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleInformationFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "informationSection")} disabled={formData.informationSection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Traditional Photography Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Traditional Photography Section</h3>
                                    <CButton onClick={() => handleAddField("traditionalPhotographySection")} className="add-field-button">
                                        Add Traditional Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.traditionalPhotographySection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`traditional_${field.id}`}>Traditional {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`traditional_${field.id}`}
                                                    name={`traditionalPhotographySection[${field.id}]`}
                                                    placeholder={`Traditional Details ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleTraditionalFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "traditionalPhotographySection")} disabled={formData.traditionalPhotographySection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Creativio Shots Section */}
                            <div className="section-container">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3 className="section-title">Creativio Shots Section</h3>
                                    <CButton onClick={() => handleAddField("creativioShotsSection")} className="add-field-button">
                                        Add Creativio Field
                                    </CButton>
                                </div>
                                <div className="main-body-container">
                                    {formData.creativioShotsSection.map((field, index) => (
                                        <div key={field.id} className="mt-20">
                                            <label htmlFor={`creativio_${field.id}`}>Creativio {field.id} Details:</label>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <input
                                                    type="text"
                                                    id={`creativio_${field.id}`}
                                                    name={`creativioShotsSection[${field.id}]`}
                                                    placeholder={`Creativio Details ${field.id}`}
                                                    value={field.value}
                                                    onChange={(e) => handleCreativioFieldChange(e, index)}
                                                    required
                                                />
                                                <CButton onClick={() => handleRemoveField(index, "creativioShotsSection")} disabled={formData.creativioShotsSection.length <= 2}>
                                                    Remove
                                                </CButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button className="custom-button primary active mt-25" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}
