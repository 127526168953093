import React, { useEffect } from "react";
import "./styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { ICONS, IMAGES } from "assets";
import LoadingScreen from "components/LandingComponents/loading-screen";
import { useSelector } from "react-redux";
import { getLandingFaqs } from "store/slices/landingSite/reducer";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { LANDING_THEME } from "utils/landingTheme";

export function Faqs() {
  const [fetchData] = useDispatchWithAbort(getLandingFaqs);
  const { HEADER_HEIGHT } = LANDING_THEME;

  const {
    faqs: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading && !data?.length) return <LoadingScreen />;

  return (
    <Stack
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          background: `url(${IMAGES.HeroBackground})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          transform: "rotate(180deg);",
          transformOrigin: "center",
        },
      }}
    >
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Stack sx={{ gap: 10 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              Frequently Asked Questions
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Everything You Need to Know Before You Join
            </Typography>
          </Stack>
          {!data?.length ? (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
              >
                No Data Found!
              </Typography>
            </Stack>
          ) : (
            <Stack sx={{ gap: 4 }}>
              {data?.map(({ id, question, answer }) => {
                return (
                  <Accordion
                    key={id}
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "20px !important",
                      "&::before": {
                        height: "unset",
                      },
                      boxShadow: "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ICONS.ArrowDownward
                          sx={{ color: theme.palette.primary.main }}
                        />
                      }
                      aria-controls={`panel${id}-content`}
                      id={`panel${id}-header`}
                      sx={{ my: 1.5 }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.primary.extraDark,
                          fontWeight: 600,
                        }}
                      >
                        {question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">{answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
