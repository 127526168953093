import { ReactComponent as ScaleImg } from "assets/imgs/create/scale.svg";
import { ReactComponent as EditImg } from "assets/imgs/create/edit.svg";
import { ReactComponent as DownloadImg } from "assets/imgs/create/download.svg";
import { ReactComponent as HeartImg } from "assets/imgs/create/heart.svg";
import { ReactComponent as HeartFillImg } from "assets/imgs/create/heart_fill.svg";
import { ReactComponent as ViewImg } from "assets/imgs/create/view.svg";
import { ReactComponent as IcoDelete } from "assets/imgs/action/icon_delete.svg";
import { ReactComponent as IcoHd } from "assets/imgs/create/hd.svg";
import { Tooltip } from "react-tooltip";
import "./styles.scss";
import CImage from "../CImage";
import getFullName from "utils/getFullName";

export function PhotoCard({
  image = null,
  images = [],
  onView = null,
  onUpScale = null,
  onEdit = null,
  onDownload = null,
  onLike = null,
  onDelete = null,
  onClarity = null,
  deleteImage = false,
}) {
  if (!image) return null;
  const getImagePath = (image) => {
    // if (image.smallestImagePath) return image.smallestImagePath
    if (image.smallImagePath) return image.smallImagePath;

    return image.image;
  };

  const getImagePlaceholder = (image) => {
    if (image.smallestImagePath) return image.smallestImagePath;
    if (image.smallImagePath) return image.smallImagePath;

    return image.image;
  };

  const formatTooltipId = (name) => {
    return `${name}-${image.id}`;
  };

  return (
    <div className="result-photo-card">
      <CImage
        src={getImagePath(image)}
        alt={image.seed}
        // width={310}
        // height={314}
        placeholder={getImagePlaceholder(image)}
        style={{ width: "100%" }}
      />
      <div className="card-action-bar">
        <div className="first-part">
          {onView && (
            <ViewImg
              data-tooltip-id={formatTooltipId("tip-view")}
              data-tooltip-variant="light"
              data-tooltip-content="View"
              className="action-btn"
              onClick={() => onView(image, images)}
            />
          )}
        </div>
        <div className="last-part">
          {onClarity && (
            <IcoHd
              data-tooltip-id={formatTooltipId("tip-clarity")}
              data-tooltip-variant="light"
              data-tooltip-content="HD Clarity"
              className="action-btn"
              onClick={() => onClarity(image)}
            />
          )}
          {onUpScale && (
            <ScaleImg
              data-tooltip-id={formatTooltipId("tip-scale")}
              data-tooltip-variant="light"
              data-tooltip-content="Upscale"
              className="action-btn"
              onClick={() => onUpScale(image)}
            />
          )}
          {onEdit && (
            <EditImg
              data-tooltip-id={formatTooltipId("tip-edit")}
              data-tooltip-variant="light"
              data-tooltip-content="Edit"
              className="action-btn"
              onClick={() => onEdit(image, images)}
            />
          )}
          {onDownload && (
            <DownloadImg
              data-tooltip-id={formatTooltipId("tip-download")}
              data-tooltip-variant="light"
              data-tooltip-content="Download"
              className="action-btn"
              onClick={() => onDownload(image.image, "download.png")}
            />
          )}
          {deleteImage && onDelete && (
            <IcoDelete
              data-tooltip-id={formatTooltipId("tip-delete")}
              data-tooltip-variant="light"
              data-tooltip-content="Delete"
              className="action-btn"
              onClick={() => onDelete(image.id)}
            />
          )}
          {onLike &&
            (image.liked ? (
              <div className="liker">
                <HeartFillImg
                  data-tooltip-id={formatTooltipId("tip-unlike")}
                  data-tooltip-variant="light"
                  data-tooltip-content="Unlike"
                  className="action-btn"
                  onClick={() => onLike(image.id, image.liked)}
                />
                {image.like_ct}
              </div>
            ) : (
              <div className="liker">
                <HeartImg
                  data-tooltip-id={formatTooltipId("tip-like")}
                  data-tooltip-variant="light"
                  data-tooltip-content="Like"
                  className="action-btn"
                  onClick={() => onLike(image.id, image.liked)}
                />
                {image.like_ct}
              </div>
            ))}
        </div>
      </div>
      <div className="image_info">
        {image.generate_type === "upscale" ? (
          <div className="generate_type upscaled">Upscaled</div>
        ) : image.generate_type === "hd-clarity" ? (
          <div className="generate_type upscaled">HD</div>
        ) : (
          ""
        )}
        <div className="created-user">{getFullName(image.user)}</div>
      </div>
      <Tooltip id={formatTooltipId("tip-view")} />
      <Tooltip id={formatTooltipId("tip-scale")} />
      <Tooltip id={formatTooltipId("tip-clarity")} />
      <Tooltip id={formatTooltipId("tip-edit")} />
      <Tooltip id={formatTooltipId("tip-download")} />
      <Tooltip id={formatTooltipId("tip-like")} />
      <Tooltip id={formatTooltipId("tip-unlike")} />
      <Tooltip id={formatTooltipId("tip-delete")} />
    </div>
  );
}
