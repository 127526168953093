import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import defaultImage from "assets/imgs/default/blog_default.jpg";
import moment from "moment";
import "./blogDetail.scss";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { ICONS } from "assets";
import { PAGES } from "utils/urls";
import LoadingScreen from "components/LandingComponents/loading-screen";
import { getLandingBlogDetails } from "store/slices/landingSite/reducer";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { useSelector } from "react-redux";
import { LANDING_THEME } from "utils/landingTheme";

export function BlogDetail() {
  const { slug } = useParams();

  const [fetchData] = useDispatchWithAbort(getLandingBlogDetails);
  const { HEADER_HEIGHT } = LANDING_THEME;
  const {
    blogDetails: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({ slug });
  }, [fetchData, slug]);

  if (loading) return <LoadingScreen />;

  return (
    <>
      {!data?.data ? (
        <Stack
          sx={{
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
          >
            No Data Found!
          </Typography>
        </Stack>
      ) : (
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Stack sx={{ gap: { xs: 4, sm: 6 } }}>
            <Stack sx={{ gap: 2 }}>
              <Typography
                variant="h3"
                sx={{
                  background: theme.palette.common.black,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 600,
                  fontSize: 45,
                  textAlign: "center",
                }}
              >
                {data?.data?.title || "-"}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.grey[500],
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Published on:{" "}
                <Box component="span" sx={{ color: "#007bff" }}>
                  {moment(data?.data?.created_at).format("MMM D, YYYY") || "-"}
                </Box>
              </Typography>
            </Stack>

            <Divider />

            <Box
              component="img"
              src={data?.data?.image || defaultImage}
              alt={data?.data?.title}
              sx={{
                width: "100%",
                aspectRatio: "16/9",
                objectFit: "cover",
                borderRadius: 3,
                maxWidth: "900px",
                alignSelf: "center",
                // maxWidth: "500px",
              }}
            />

            <Divider />

            <Stack>
              <Box
                dangerouslySetInnerHTML={{
                  __html: data?.data?.description,
                }}
                sx={{
                  p: {
                    color: theme.palette.common.black,
                    fontSize: 18,
                  },
                }}
              />
            </Stack>

            <Divider />

            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: !data?.previous ? "end" : "space-between",
              }}
            >
              {data?.previous && (
                <Button
                  component={Link}
                  to={`${PAGES.BLOGS.path}/${data?.previous.slug}`}
                  variant="outlined"
                  sx={{ fontWeight: 600, textTransform: "none" }}
                  startIcon={
                    <ICONS.ArrowBack sx={{ fontSize: "17px !important" }} />
                  }
                  disabled={loading}
                >
                  Previous
                </Button>
              )}

              {data?.next && (
                <Button
                  component={Link}
                  to={`${PAGES.BLOGS.path}/${data?.next?.slug}`}
                  variant="outlined"
                  sx={{
                    fontWeight: 600,
                    textTransform: "none",
                    alignSelf: "end",
                  }}
                  endIcon={
                    <ICONS.ArrowBack
                      sx={{
                        fontSize: "17px !important",
                        transform: "rotate(180deg)",
                      }}
                    />
                  }
                  disabled={loading}
                >
                  Next
                </Button>
              )}
            </Stack>
          </Stack>
        </Container>
      )}
    </>
  );
}
